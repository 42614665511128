import "./style.css";
import { motion, useInView, useAnimation } from "framer-motion";
import React, { useRef, useEffect } from "react";
export default function Video() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
      className="sm:mt-12 sm:pt-0 mt-96 pt-44 flex justify-center items-center h-screen"
    >
      <div className="h-96 w-96 md:w-8/12 md:h-[32rem] sm:mt-12 rounded-xl  shadow-xl ring-1 ring-gray-400/10 sm:w-[55rem]">
        <iframe
          src="https://www.youtube.com/embed/PDf9LDagvK0?autoplay=0&modestbranding=1&controls=0&clipboard-write=0&loop=1"
          title="YouTube video player"
          className="w-full h-full rounded-xl aspect-video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </div>
    </motion.div>
  );
}
