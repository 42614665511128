import React from "react";
import { Helmet } from "react-helmet";
import Landing from "../Landing";
import Footer from "../../../footer";
import OverView from "../Overview";
import MyNavBar from "../../../Navbar";
export default function Products() {
  return (
    <div>
      <Helmet title="Produtos" />
      <MyNavBar marginTop="4" />
      <Landing />
      <OverView />
      <Footer />
    </div>
  );
}
