import "./style.css";
import React, { useState, useEffect } from "react";
export default function Landing() {
  const img = require("../../../../imgs/BANNER.webp");

  const [currentYear, setCurrentYear] = useState(null);

  useEffect(() => {
    const getCurrentYear = () => {
      const year = new Date().getFullYear();
      const companyFoundationYear = 1957;
      setCurrentYear(year - companyFoundationYear);
    };

    getCurrentYear();
  }, []);
  return (
    <div>
      <div className="justify-center flex items-center">
        <div className="pb-36 pt-36 liquid sm:p-[15rem]">
          <h2 className="text-4xl sm:text-7xl font-bold text-blue-600 p-5">
            {currentYear} anos oferecendo qualidade e pureza à família
            brasileira.
          </h2>
          <h2 className="text-4xl sm:text-7xl font-bold text-blue-600 p-5">
            {currentYear} anos oferecendo qualidade e pureza à família
            brasileira.
          </h2>
          <h2 className="text-4xl sm:text-7xl font-bold text-blue-600 p-5">
            {currentYear} anos oferecendo qualidade e pureza à família
            brasileira.
          </h2>
          <h2 className="text-4xl sm:text-7xl font-bold text-blue-600 p-5">
            {currentYear} anos oferecendo qualidade e pureza à família
            brasileira.
          </h2>
        </div>
        <div className="justify-center flex items-center">
          <img
            loading="lazy"
            alt=""
            className="hidden sm:flex w-10/12"
            src={img}
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <img
          loading="lazy"
          alt=""
          className="sm:hidden flex w-10/12"
          src={img}
        />
      </div>
    </div>
  );
}
