import {
  createStyles,
  Image,
  Accordion,
  Grid,
  Col,
  Container,
  Title,
} from "@mantine/core";
import image from "../../../../imgs/FAQ.webp";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";
const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
  },

  title: {
    marginBottom: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color: "black",
  },
}));

export default function Faq() {
  const { classes } = useStyles();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
      className={`${classes.wrapper}`}
    >
      <Container size="lg">
        <Grid id="faq-grid" gutter={50}>
          <Col span={12} md={6}>
            <Image src={image} alt="Frequently Asked Questions" />
          </Col>
          <Col span={12} md={6}>
            <Title order={2} ta="left" className={classes.title}>
              Perguntas Frequentes
            </Title>

            <Accordion
              chevronPosition="right"
              defaultValue="reset-password"
              variant="separated"
            >
              <Accordion.Item className={classes.item} value="reset-password">
                <Accordion.Control>O que é água mineral?</Accordion.Control>
                <Accordion.Panel>
                  Água mineral é uma água proveniente de fontes naturais, como
                  nascente ou aquíferos, que passa por um processo de filtração
                  natural, não contendo adição de produtos químicos ou
                  contaminantes.
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item className={classes.item} value="another-account">
                <Accordion.Control>
                  Quais são os benefícios de consumir água mineral?{" "}
                </Accordion.Control>
                <Accordion.Panel>
                  Alem de fornecer hidratação adequada, ajuda na reposição de
                  minerais essenciais ao organismo, contribui para o bom
                  funcionamento do sistema digestivo, e auxilia na manutenção de
                  uma pele saudável.
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item className={classes.item} value="newsletter">
                <Accordion.Control>
                  É seguro consumir água mineral?{" "}
                </Accordion.Control>
                <Accordion.Panel>
                  Sim, a água mineral é considerada segura para consumo, pois
                  passa por rigorosos testes de qualidade e é regulamentada por
                  órgãos de controle, garantindo a sua pureza e potabilidade.
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item className={classes.item} value="credit-card">
                <Accordion.Control>
                  Água mineral é melhor do que água de torneira?{" "}
                </Accordion.Control>
                <Accordion.Panel>
                  A qualidade da água pode variar dependendo da região e do
                  sistema de tratamento utilizado. A água mineral é uma opção
                  para quem busca uma água com características específicas e
                  comprovada pureza, mas a qualidade da água de torneira pode
                  ser igualmente segura, desde que esteja dentro dos padrões de
                  qualidade estabelecidos pelas autoridades locais.
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item className={classes.item} value="payment">
                <Accordion.Control>
                  É necessário armazenar água mineral de alguma forma especial?{" "}
                </Accordion.Control>
                <Accordion.Panel>
                  A água mineral não requer cuidados especiais de armazenamento,
                  mas é recomendado mantê-la em local fresco, seco e protegido
                  da luz solar direta, a fim de preservar suas características e
                  qualidade.
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Grid>
      </Container>
    </motion.div>
  );
}
