import React from "react";
import { ImLocation2, ImPhone, ImWhatsapp } from "react-icons/im";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";
export default function Products() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
      className="flex justify-center items-center h-screen"
    >
      <div className="md:w-[55rem] md:h-[35rem] flex mt-12 rounded-xl shadow-xl ring-1 ring-gray-400/10">
        <div className="w-full md:w-1/2">
          <div className="p-5">
            <p className="sm:text-5xl text-blue-600 font-extrabold ">
              Entre em Contato
            </p>
            <p className="">
              Se você tem dúvidas, sugestões, reclamações ou quer entrar em
              contato com nossa equipe de vendas, fale com a gente.{" "}
            </p>
          </div>
          <div className="p-5">
            <div>
              <a
                className="no-underline flex content-between "
                href="https://goo.gl/maps/kyVoj4Pi8EPtFQjD8"
              >
                <ImLocation2 className="text-blue-600" size={20} />
                &nbsp; Estrada Vicinal Palmares Paulista - SP, &nbsp;15828-000
              </a>
            </div>
            <div>
              <a
                href="tel:01735871132"
                className="text-blue-600 no-underline flex content-between"
              >
                <ImPhone size={20} />
                &nbsp; (17) 3587-1132
              </a>
            </div>
            <div>
              <a
                href="https://wa.me/+551735871132"
                className="text-white bg-green-600 rounded-lg h-1/3 p-2 mt-2 no-underline flex"
                variant="transparent"
              >
                <ImWhatsapp size={20} className="mt-1" />
                &nbsp; envie uma mensagem
              </a>
            </div>
          </div>
        </div>
        <div className="hidden md:block w-1/2">
          <div
            style={{
              backgroundImage:
                "url('https://arenacp.com.br/carlinhos-pinheiro/wp-content/uploads/2021/06/IMG_4285.jpg')",
            }}
            className="w-full h-full bg-cover bg-center rounded-xl"
          />
        </div>
      </div>
    </motion.div>
  );
}
