import { createStyles, Container, Title, rem } from "@mantine/core";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";
import copo from "../../../../imgs/copo.webp";
import b510 from "../../../../imgs/510_1.webp";
import b510gas from "../../../../imgs/510_GAS.webp";
import b1_5 from "../../../../imgs/1_5.webp";
import b10l from "../../../../imgs/10L_1.webp";
import b10lA from "../../../../imgs/10L - 1.webp";
import b20 from "../../../../imgs/20L.webp";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: "#2372f5",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,
    [theme.fn.smallerThan("md")]: {
      flex: 1,
    },
  },

  highlight: {
    position: "relative",
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
}));

export default function OverView() {
  const { classes } = useStyles();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
    >
      <div className="p-5 w-[17rem] sm:w-full sm:flex text-blue-500">
        <Container>
          <div className={classes.inner}>
            <img
              loading="lazy"
              alt=""
              className="mr-6 sm:mr-0 w-96 sm:object-scale-down h-60 sm:h-[45rem]"
              src={b20}
            />
            <div className={classes.content}>
              <Title className={classes.title}>
                Embalagem retornável de
                <span className={classes.highlight}>
                  20 L garrafão sem gás
                </span>{" "}
              </Title>
            </div>
          </div>
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={classes.title}>
                Embalagem retornável de
                <span className={classes.highlight}>
                  10 L garrafão sem gás
                </span>{" "}
              </Title>
            </div>
            <img
              loading="lazy"
              alt=""
              className="h-60 w-96  sm:object-scale-down sm:h-[35rem]"
              src={b10lA}
            />
          </div>
          <div className={classes.inner}>
            <img
              loading="lazy"
              alt=""
              className="mr-4 h-64 w-96 sm:mr-0 sm:object-scale-down sm:h-[35rem] sm:w-[20rem]"
              src={b10l}
            />
            <div className={classes.content}>
              <Title className={classes.title}>
                Embalagem descartável de
                <span className={classes.highlight}>
                  10 L garrafão sem gás
                </span>{" "}
              </Title>
            </div>
          </div>
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={classes.title}>
                Embalagem descartável de{" "}
                <span className={classes.highlight}>1,5 L água sem gás</span>{" "}
              </Title>
            </div>
            <img
              loading="lazy"
              alt=""
              className="pl-6 h-60 sm:object-scale-down sm:ml-0 sm:h-[35rem]"
              src={b1_5}
            />
          </div>
          <div className={classes.inner}>
            <img
              loading="lazy"
              alt=""
              className="mr-4 h-60 sm:object-scale-down sm:mr-0 sm:h-[35rem]"
              src={b510gas}
            />
            <div className={classes.content}>
              <Title className={classes.title}>
                Embalagem descartável de{" "}
                <span className={classes.highlight}>510 mL água com gás</span>{" "}
              </Title>
            </div>
          </div>
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={classes.title}>
                Embalagem descartável de{" "}
                <span className={classes.highlight}>510 mL água sem gás</span>{" "}
              </Title>
            </div>
            <img
              loading="lazy"
              alt=""
              className="ml-6 h-60 sm:object-scale-down sm:ml-0 sm:h-[35rem]"
              src={b510}
            />
          </div>
          <div className={classes.inner}>
            <img
              loading="lazy"
              alt=""
              className="mr-4 h-40 sm:object-scale-down sm:ml-0 sm:h-[20rem]"
              src={copo}
            />
            <div className={classes.content}>
              <Title className={classes.title}>
                Embalagem descartável de{" "}
                <span className={classes.highlight}>200 mL Copo sem gás</span>
              </Title>
            </div>
          </div>
        </Container>
      </div>
    </motion.div>
  );
}
