import { createStyles, Container, Group, Text, rem } from "@mantine/core";
import { Link } from "react-router-dom";
import logo from "../../imgs/logonobg.webp";
import logo_facebook from "../../imgs/facebook-logo-0.webp";
import logo_instasgram from "../../imgs/[removal.ai]_tmp-649c8b61f33ba.webp";
const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(120),
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },
  links: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },
}));
const handleClick = () => {
  window.scrollTo(0, 0);
};
const actualDate = new Date();
const actualYear = actualDate.getFullYear();

export default function Footer() {
  const { classes } = useStyles();
  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Link to="/" onClick={handleClick}>
          <img loading="lazy" alt="" src={logo} style={{ height: 75 }} />
        </Link>
        <Group spacing={0} className={classes.links} position="right" noWrap>
          <a href="https://www.instagram.com/aguamineralpalmares/">
            <img loading="lazy" className="h-8" src={logo_instasgram} alt="" />
          </a>
          <a href="https://www.facebook.com/aguamineralpalmares">
            <img loading="lazy" className="h-12" src={logo_facebook} alt="" />
          </a>
        </Group>
        <Text color="dimmed" className="d-flex justify-content-end" size="sm">
          © {actualYear} Todos os direitos reservados <br /> Água Mineral
          Palmares{" "}
        </Text>
      </Container>
    </div>
  );
}
