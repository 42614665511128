import { Navbar, Nav, NavItem, Container } from "react-bootstrap";
import { IconMap, IconPhone } from "@tabler/icons-react";
import "./style.css";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";
import facebook_logo from "../../../../imgs/facebook-logo-0.webp";
import instagram_logo from "../../../../imgs/[removal.ai]_tmp-649c8b61f33ba.webp";
export default function Topheader() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
    >
      <Navbar className="d-none d-lg-block">
        <Container className="Topheadercontainer" fluid={true}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto d-flex justify-content-between">
              <NavItem>
                <span
                  style={{ color: "white" }}
                  className="d-flex justify-content-between mt-2"
                >
                  <IconPhone color="white" /> (17) 3587-1132
                </span>
              </NavItem>
              <NavItem>
                <Nav.Link
                  style={{ color: "white" }}
                  className="d-flex ml-24 justify-content-between"
                  href="https://www.google.com/maps/dir//Empresa+de+Minera%C3%A7%C3%A3o+Gomieri+Ltda.+-+Estrada+Vicinal+Palmares+Paulista+%C3%A0+Ariranha+Km+01+S%C3%ADtio+-+Santo+Ant%C3%B4nio,+Palmares+Paulista+-+SP,+15828-000,+Brasil/@-21.088345,-48.792785,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x94bc022bfb8fa187:0x81a42d9c2a57f856!2m2!1d-48.7930424!2d-21.0885851?hl=pt-BR"
                >
                  <IconMap color="white" />
                  Estrada Vicinal Palmares Paulista - Ariranha - Rural, Palmares
                  Paulista - SP, 15828-000
                </Nav.Link>
              </NavItem>
            </Nav>
            <Nav className="ms-auto">
              <NavItem>
                <Nav.Link href="https://www.facebook.com/aguamineralpalmares/?locale=pt_BR">
                  <img
                    loading="lazy"
                    src={facebook_logo}
                    alt=""
                    className="h-8"
                  />
                </Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link href="https://www.instagram.com/aguamineralpalmares/">
                  <img
                    loading="lazy"
                    src={instagram_logo}
                    alt=""
                    className="h-6 mt-[0.34rem]"
                  />
                </Nav.Link>
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </motion.div>
  );
}
