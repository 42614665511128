import Landing from "../Landing";
import MyNavBar from "../../../Navbar/";
import Footer from "../../../footer/index.js";
import Reflorestation from "../Reflorestation";
import { Helmet } from "react-helmet";
export default function Reciclagem() {
  return (
    <div>
      <Helmet title="Meio Ambiente" />
      <MyNavBar marginTop="4" />
      <Landing />
      <Reflorestation />
      <Footer />
    </div>
  );
}
