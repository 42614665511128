import React from "react";
import { BrowserRouter } from "react-router-dom";
import AnimatedRoutes from "../AnimatedRoutes";

const AppRouter = () => {
  return (
    <BrowserRouter scrollToTop={true}>
      <AnimatedRoutes />
    </BrowserRouter>
  );
};

export default AppRouter;
