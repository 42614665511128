import { Helmet } from "react-helmet";
import Contact from "../Contacts";
import Footer from "../../../footer";
import MyNavBar from "../../../Navbar";
export default function ContactMain() {
  return (
    <div>
      <Helmet title="Contato" />
      <MyNavBar marginTop="4" />
      <Contact />
      <Footer />
    </div>
  );
}
