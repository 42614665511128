import {
  createStyles,
  Container,
  Text,
  Title,
  Button,
  rem,
} from "@mantine/core";
import "./style.css";
import { Link } from "react-router-dom";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useState, useEffect, useCallback } from "react";
const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
  },

  content: {
    maxWidth: rem(480),
    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    position: "relative",
    color: "#272066",
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
  card: {
    height: rem(320),
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  titleCard: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginTop: theme.spacing.xs,
    fontSize: rem(15),
  },

  category: {
    color: theme.black,
    opacity: 0.7,
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));
export default function Infos() {
  const { classes } = useStyles();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  const [marginTop, setMarginTop] = useState("3rem");

  const handleScroll = useCallback(() => {
    if (window.scrollY > 0 && marginTop === "3rem") {
      setMarginTop("0");
    } else if (window.scrollY === 0 && marginTop === "0") {
      setMarginTop("3rem");
    }
  }, [marginTop]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
      className="flex justify-center items-center h-screen"
    >
      <div className="rounded-xl sm:w-[55rem] shadow-xl ring-1 ring-gray-400/10">
        <Container className="d-flex justify-content-between bk-mobile">
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={`${classes.title}`}>Nossa história </Title>
            </div>
          </div>
          <div>
            <Text
              variant="gradient"
              gradient={{ deg: 180, from: "black", to: "white" }}
            >
              Dona Mariana Theodoro de Faria, esposa de “Chico Nenê”, falecida
              em 10 de Outubro de 1.910, deixou para uma das filhas, Maria
              Theodora, casada com o cronista José Barbosa, uma gleba de terras
              na confluência do córrego Boa Vista do Generoso com o rio da Onça,
              onde na sua montante, havia uma nascente de água límpida e
              cristalina.
            </Text>
          </div>
          <div className="flex justify-start sm:justify-end">
            <Link to="/historia" onClick={handleClick}>
              <Button className="w-[12rem] rounded-xl" variant="light">
                Saiba Mais
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    </motion.div>
  );
}
