import React from "react";
import { Chrono } from "react-chrono";
const actualDate = new Date();
const actualYear = actualDate.getFullYear();
const items = [
  {
    title: "1910",
    cardTitle:
      "A trajetória da água mais pura e cristalina da região até os dias de hoje",
    cardDetailedText:
      "Dona Mariana Theodoro de Faria, esposa de “Chico Nenê”, falecida em 10 de Outubro de 1.910, deixou para uma das filhas, Maria Theodora, casada com o cronista José Barbosa, uma gleba de terras na confluência do córrego Boa Vista do Generoso com o rio da Onça, onde na sua montante, havia uma nascente de água límpida e cristalina.",
  },
  {
    title: "1920 - 1941",
    cardTitle:
      "Descoberta e exploração de propriedades medicinais da água de uma nascente por Nagib Miguel e José Miguel",
    cardDetailedText: `Posteriormente adquirida pelos comerciantes Nagib Miguel e José Miguel, se interessaram em analisar a água da nascente local, visto que muitas pessoas relatavam sobre propriedades medicinais, inclusive acelerando a cicatrização de feridas na pele. A análise foi realizada no Laboratório “Oswaldo Cruz”, pelos analistas químicos, Dr. Paulo de Andrade e H. Potel, e no Laboratório de Análises Clínicas do Estado (atual Instituto “Adolfo Lutz”), comprovando a excelência de sua potabilidade e suposta ação radioativa, instalando-se então a captação, engarrafamento, rotulagem, acondicionamento e distribuição.\n Se na época, tudo parecia ser o mais moderno possível, percebeu-se, muitos anos passados, que a captação assemelhava-se a uma tumba de cimento e de forma ovalada, com reservatório revestido de azulejos branco, com viseira de vidro, e canículas para engarrafamento (que se fazia a céu aberto). A água excedente era canalizada diretamente ao rio da Onça, não muito distante, tudo de forma rudimentar, sem os preceitos atuais de higiene.`,
  },
  {
    title: "1957",
    cardTitle: "Nascimento da Fonte Santo Elias",
    cardDetailedText:
      "Em 1.941, João Gomieri Sobrinho adquiriu a área, constituída de cerca de quinze hectares, onde se localizava a Fonte “Santo Elias”, cujas águas eram comercializadas por terceiros concessionários, de forma artesanal, sem muitos progressos na sua expansão. Pelo Decreto nº 42.067 , de 19 de Agosto de 1.957, (D. O. U. de 23 de Agosto de 1.957) , o então Presidente da República, Juscelino Kubitschek, autorizou João Gomieri Sobrinho “a pesquisar água mineral radioativa em terreno de sua propriedade no imóvel denominado “Fazenda Santo Antônio”, no distrito de Jaguateí município de Ariranha (atualmente município de Palmares Paulista), onde justamente existia a velha fonte.\n ",
  },
  {
    title: "1973",
    cardTitle: "Alteração do Decreto de Lavra",
    cardDetailedText:
      "Já com essa autorização em mãos, João Gomieri Sobrinho começou a construção do seu primeiro barracão para o envase de água mineral proveniente da fonte intitulada como “Fonte Santo Elias”. Posteriormente organizada pelos irmãos João Pedro Gomieri e Antônio Tadeu Gomieri, netos de João Gomieri Sobrinho a “Empresa de Mineração Gomieri Ltda.”, agora titular do Decreto de Lavra nº 72.242 , de 11 de Maio de 1.973, (D. O. U. de 16 de Maio de 1.973), efetuou-se através do LAMIN/CPRM, novo estudo “in loco” e análises laboratoriais da água da Fonte “Santo Elias”. Técnicos do Laboratório LAMIN, do Rio de Janeiro, estiveram durante uma semana, realizando testes e pesquisas das águas, comprovando-se a presença inesperada do elemento Flúor, na forma de Fluoreto de Potássio, eis porque passaram a se classificar como água mineral fluoretada, e não mais radioativa, como a princípio se imaginava.",
  },
  {
    title: "1999",
    cardTitle:
      "Descoberta das propriedades terapêuticas, investimentos em tecnologia, qualidade e sua crescente popularidade",
    cardDetailedText:
      "Em conformidade com o laudo de 17 de Novembro de 1.999, a composição química (mg/L) de classificação da agua da Fonte “Santo Elias” (ou Fonte Palmares) como “Água Mineral Fluoretada”, com as seguintes propriedades terapêuticas: diurética, laxativa se ingerida em jejum, eliminando do organismo toxinas e auxiliando na fixação de vitaminas, como hidratante natural, a ação do Flúor natural fortalece os ossos, especialmente a dentina, prevenindo cáries, sem falar de sua limpidez e cristalinidade, fatores de sua absoluta potabilidade.\n Com a contratação do químico e crenólogo, Sérgio L. Destro, modernas máquinas e equipamentos de envasar água mineral foram adquiridas (‘uma tecnologia de 1º Mundo, segundo o “slogan” de sua publicidade). Em função dos preceitos de rigorosa higiene (sem contato manual) e rígido controle de qualidade, a Água Mineral Palmares está sendo credenciada como água de mesa preferida em muitas cidades de todo o Estado, ao nível das águas de Lindóia e São Lourenço.",
  },
  {
    title: "2000",
    cardTitle:
      "Propriedades Terapêuticas e Medicinais para Desintoxicação e Equilíbrio do Corpo",
    cardDetailedText:
      "Ainda falando de suas propriedades terapêuticas e medicinais, a Água Mineral Palmares tem sido cada vez mais indicada por fisioterapeutas, personal trainers, médicos das mais diversas especialidades, dentre elas endocrinologia, oncologia, cardiologia, urologia e muitas outras.\n Devido ao seu pH elevado, ajuda a desintoxicar e balancear a acidez do nosso corpo quando consumida regularmente. Isso porque o pH do nosso sangue encontra-se na faixa de 7,4 mas a partir dos 40 anos naturalmente nosso organismo vai se acidificando. Consumindo diariamente água mineral alcalina, estamos contribuindo para que nosso organismo seja um ambiente menos favorável a instalação de alguns tipos doenças, principalmente àquelas associadas a degeneração celular, ossos, distúrbios renais e de cólon. Com índices de bicarbonato e magnésio superiores a muitas águas da nossa região, a Água Mineral Palmares ainda é indicada para ajudar a controlar a acidez estomacal sendo amplamente recomendada para pessoas que sofrem de úlcera ou gastrite.",
  },
  {
    title: "2009",
    cardTitle:
      "Da Fonte Santo Elias ao Poço Antônio Humberto Gomieri, Garantindo a Qualidade e Satisfação dos Consumidores",
    cardDetailedText:
      "Com o crescimento das vendas impulsionado pela ótima aceitação dos consumidores, a Água Mineral Palmares foi gradativamente se modernizando. A antiga Fonte Santo Elias já não comportava abastecer a crescente demanda do mercado e finalmente ganhou sua “aposentadoria”, sendo substituída por um moderno e bem construído poço que provou ter uma água mineral ainda de qualidades físicas e minerais superiores. Hoje toda água engarrafada pela empresa provém da Fonte Antônio Humberto Gomieri, nome este dado em homenagem ao filho de João Gomieri Sobrinho e pai de João Pedro e Antônio Tadeu.",
  },
  {
    title: "2014",
    cardTitle:
      "A Preferida para Harmonizar com Cafés Gourmet e Ativar as Papilas Gustativas",
    cardDetailedText:
      "A Água Mineral Palmares proveniente da Fonte “Antônio Humberto Gomieri” possui pH de 9,11 sendo uma das poucas águas minerais do estado de São Paulo com valores superiores a 9. Cristalina, isenta de qualquer sabor e por apresentar uma textura “leve”, a Água Mineral Palmares é a preferida em vários lares e estabelecimentos comerciais. Sua versão com gás harmoniza perfeitamente com diversas variedades de cafés “Gourmet” e quando ingerida antes de se tomar a bebida ajuda a limpar e ativar as papilas gustativas. Seu papel é importante para deixar a cavidade bucal pronta para valorizar ao máximo os sabores singulares de um café especial.",
  },
  {
    title: `${actualYear}`,
    cardTitle: "Água Mineral Palmares - Qualidade, Saúde e Meio Ambiente",
    cardDetailedText:
      "Para as pessoas que sofrem de hipertensão arterial outra boa notícia: dentre as águas alcalinas com elevado pH, a Água Mineral Palmares apresenta baixo teor de sódio. Segundo a OMS (Organização Mundial de Saúde) devemos consumir no máximo 2.000 mg de sódio por dia. Uma pessoa que bebe 3 litros de Água Mineral Palmares por dia estará ingerindo cerca de 3% da quantidade máxima de sódio que se deve consumir por dia.\n Outro ponto crucial e de vital importância para toda empresa que trabalha com um produto natural e disponibilizado pela natureza é o cuidado com a preservação do meio ambiente e de sua mata ciliar. A Água Mineral Palmares promove anualmente um programa de reflorestamento e ampliação da mata ciliar da Fazenda Santo Antônio, local em que está localizada a empresa. Pelo menos 2.000 mudas de árvores de espécies nativas são plantadas anualmente pela empresa. Além disso, implantamos um programa de Logística Reversa que consiste no retorno das embalagens descartáveis dos produtos consumidos por nossos clientes visando o descarte apropriado e a preservação ambiental, sendo as mesmas recolhidas e direcionadas para cooperativas de reciclagem. Ainda dentro de um projeto de conservação, hoje a água que não é mais engarrafada e que provém da antiga Fonte Santo Elias permanece preservada e toda água que brota de sua nascente vai diretamente abastecer o rio da Onça.\n Qualidade, compromisso com a satisfação dos nossos clientes e cuidado ambiental são os pilares mestres da Água Mineral Palmares. Ao ter em sua casa e oferecer à sua família você pode ter a certeza e a tranquilidade de que estará consumindo um ótimo produto. Beba saúde, beba Água Mineral Palmares, saudável como sua vida.",
    cardExtraContent:
      "Referências Bibliográficas\n “Sombras do Passado”, QUAGLIA, V. C., editora Impressora Catanduvense, 1ª edição, 2000, pag.84.\n  Arquivo histórico da Empresa de Mineração Gomieri Ltda.",
  },
];
export default function Hadline() {
  return (
    <div className="mt-40">
      <div>
        <h1 className="font-black font-2xl text-blue-500 text-center">
          A HISTÓRIA DA ÁGUA MINERAL PALMARES
        </h1>
      </div>
      <div className="sm:-ml-[2rem]">
        <Chrono
          items={items.title}
          theme={{
            secondary: "#2372f5",
          }}
          fontSizes={{ cardText: "0.5rem" }}
          mode="VERTICAL"
          itemWidth={220}
          contentDetailsHeight={220}
          cardHeight={220}
          useReadMore={true}
          nestedCardHeight={220} //
          hideControls={true}
        >
          {items.map((item) => (
            <div>
              <h2 className="text-blue-600 font-bold">{item.title}</h2>
              <p className="text-blue-600 font-bold text-xl">
                {item.cardTitle}
              </p>
              {item.cardDetailedText.split("\n").map((line, index) => (
                <p key={index}>{line}</p>
              ))}{" "}
              {item.cardExtraContent &&
                item.cardExtraContent.split("\n").map((lina, indexa) => (
                  <p className="text-sm text-blue-600" key={indexa}>
                    {lina}
                  </p>
                ))}{" "}
            </div>
          ))}
        </Chrono>
      </div>
    </div>
  );
}
