import { Helmet } from "react-helmet";
import Headline from "../headline";
import Footer from "../../../footer";
import MyNavBar from "../../../Navbar/";
export default function History() {
  return (
    <div>
      <Helmet title="História" />
      <MyNavBar marginTop="4" />
      <Headline />
      <Footer />
    </div>
  );
}
