import { createStyles, Text, SimpleGrid, Container, rem } from "@mantine/core";
import { AiFillStar } from "react-icons/ai";
import { motion, useInView, useAnimation } from "framer-motion";
import { useState, useEffect, useRef } from "react";
const useStyles = createStyles((theme) => ({
  feature: {
    position: "relative",
    paddingTop: theme.spacing.xl,
    paddingLeft: theme.spacing.xl,
  },

  overlay: {
    position: "absolute",
    height: rem(100),
    width: rem(160),
    top: 0,
    left: 0,
  },

  content: {
    position: "relative",
    zIndex: 2,
  },

  icon: {
    color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
      .color,
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

interface FeatureProps extends React.ComponentPropsWithoutRef<"div"> {
  icon: React.FC<any>;
  title: string;
  description: string;
}

function Feature({
  icon: Icon,
  title,
  description,
  className,
  ...others
}: FeatureProps) {
  const { classes, cx } = useStyles();

  return (
    <div className="rounded-xl  shadow-xl ring-1 ring-blue-700/10 pb-4 pr-3">
      <div className={cx(classes.feature, className)} {...others}>
        <div className={classes.overlay} />
        <div className={classes.content}>
          <Icon size={rem(38)} className={classes.icon} color="gold" />
          <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.title}>
            {title}
          </Text>
          <Text c="bold" fz="sm">
            {description}
          </Text>
        </div>
      </div>
    </div>
  );
}

function NumeroAteMaximo({ maximo, tempo, texto, maisnumero }) {
  const [numero, setNumero] = useState(0);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  useEffect(() => {
    if (isInView) {
      const intervalId = setInterval(() => {
        if (numero < maximo) {
          setNumero((prevNumero) => prevNumero + maisnumero);
        } else {
          clearInterval(intervalId);
        }
      }, tempo);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [numero, maximo, tempo, isInView, maisnumero]);
  return (
    <div ref={ref}>
      <p>
        {numero
          .toLocaleString("en-US", { maximumFractionDigits: 2 })
          .replace(/,/g, ".")}
        {texto}
      </p>
    </div>
  );
}
const mockdata = [
  {
    icon: AiFillStar,
    title: <NumeroAteMaximo maximo={1900000} tempo={20} maisnumero={10000} />,
    description:
      "Mais de 1.900.000 unidades de descartáveis destinadas a reciclagem todos os anos.",
  },
  {
    icon: AiFillStar,
    title: <NumeroAteMaximo maximo={10000} tempo={7.5} maisnumero={100} />,
    description: `Mudas de árvores nativas plantadas nos últimos 5 anos - Compromisso da Água Mineral Palmares com o meio ambiente.`,
  },
  {
    icon: AiFillStar,
    title: <NumeroAteMaximo maximo={66} texto=" " tempo={80} maisnumero={1} />,
    description: "Anos envasando água mineral alcalina de qualidade.",
  },
];

export default function FeatureSection() {
  const items = mockdata.map((item) => <Feature {...item} key={item.title} />);
  const ref = useRef(null);
  const isInViewalt = useInView(ref, { once: true });

  const mainControls = useAnimation();
  useEffect(() => {
    if (isInViewalt) {
      mainControls.start("visible");
    }
  }, [isInViewalt, mainControls]);

  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
    >
      <Container mt={30} mb={30} size="lg">
        <SimpleGrid
          cols={3}
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          spacing={50}
        >
          {items}
        </SimpleGrid>
      </Container>
    </motion.div>
  );
}
