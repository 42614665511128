import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";
const bottles = require("../../../../imgs/BANNER.webp");

export default function Landing() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
      className="relative mt-12 isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0"
    >
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Composição Química (mg/L)
              </h1>
              <p className="mt-6 text-xl leading-8 text-black">
                Estrôncio.................................... 0,223
                <br />
                Cálcio.......................................... 3,730
                <br />
                Magnésio.................................... 0,175
                <br />
                Potássio...................................... 0,328
                <br />
                Sódio........................................ 23,800
                <br />
                Carbonato................................ 12,00
                <br />
                Bicarbonato............................. 50,98
                <br />
                Fluoreto..................................... 0,14
                <br />
                Nitrato....................................... 0,09
                <br />
                Cloreto...................................... 0,13
                <br />
                Fosfato...................................... 0,08
                <br />
                Sulfato....................................... 0,04
                <br />
                Cromo..................................... 0,039
                <br />
                Vanádio................................... 0,018
                <br />
                Zinco....................................... 0,014 <br />
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            loading="lazy"
            className="hidden  sm:flex w-[25rem] max-w-none sm:w-[57rem]"
            src={bottles}
            alt=""
          />
        </div>
        <div className="sm:hidden -mt-16 flex justify-center items-center">
          <img
            loading="lazy"
            className="w-[25rem] max-w-none"
            src={bottles}
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="hidden sm:flex text-black max-w-xl text-base leading-7 lg:max-w-lg">
              <p>
                <span className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                  Boletim nº 596/LAMIN-SP, de 05/10/2022
                </span>
                <br />
                pH a 25 ºC
                ..............................................................
                9,11
                <br /> Temperatura da água na fonte ..........................
                24,5 ºC <br />
                Condutividade a 25 ºC .......................................
                114,1 µS/cm
                <br /> Resíduo de evaporação a 180 ºC ........................
                98,11mg/L
                <br />
                Classificação: Água Mineral Fluoretada
              </p>
            </div>
            <div className="sm:hidden text-black  text-sm leading-7">
              <p>
                <span className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                  Boletim nº 596/LAMIN-SP, de 05/10/2022
                </span>
                <br />
                pH a 25 ºC............................................. 9,11
                <br /> Temperatura da água na fonte........... 24,5 ºC <br />
                Condutividade a 25 ºC...................... 114,1 µS/cm
                <br /> Resíduo de evaporação a 180 ºC,
                calculado................. 95,11 mg/L
                <br />
                Classificação: Água Mineral Fluoretada
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
