import React, { useRef, useEffect } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import Autoplay from "embla-carousel-autoplay";
import {
  createStyles,
  Image,
  Card,
  Text,
  Container,
  Group,
  Title,
  getStylesRef,
  rem,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import reflorestation01 from "../../../../imgs/reflorestation01.webp";
import reflorestation02 from "../../../../imgs/reflorestation02.webp";
import reflorestation03 from "../../../../imgs/reflorestation03.webp";
import img01 from "../../../../imgs/16.webp";
import img02 from "../../../../imgs/17.webp";
import img03 from "../../../../imgs/18.webp";
import img04 from "../../../../imgs/19.webp";
import img05 from "../../../../imgs/20.webp";
import img06 from "../../../../imgs/21.webp";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: `calc(${theme.spacing.xl} * 0.5)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      flex: 1,
    },
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },

  carousel: {
    "&:hover": {
      [`& .${getStylesRef("carouselControls")}`]: {
        opacity: 1,
      },
    },
  },
  carouselControls: {
    ref: getStylesRef("carouselControls"),
    transition: "opacity 150ms ease",
    opacity: 0,
  },
  carouselIndicator: {
    width: rem(4),
    height: rem(4),
    transition: "width 250ms ease",
    "&[data-active]": {
      width: rem(16),
    },
  },
}));
const textTwo =
  "Nos últimos cinco anos, mais de 10.000 mudas de árvores nativas foram replantadas. Um poderoso testemunho do nosso compromisso contínuo com a preservação ambiental";
const textOne =
  "Grande parte da área no entorno de nossa fonte se mantém preservada, com a presença de vários animais silvestres e uma grande diversidade de árvores nativas.";

const images = [reflorestation01, reflorestation02, reflorestation03];
function CarouselCard() {
  const { classes } = useStyles();
  const slides = images.map((images) => (
    <Carousel.Slide key={images}>
      <Image src={images} height={220} />
    </Carousel.Slide>
  ));
  const autoplay = useRef(Autoplay({ delay: 3000 }));
  return (
    <Card radius="md" withBorder padding="xl">
      <Card.Section>
        <Carousel
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          loop
          classNames={{
            root: classes.carousel,
            controls: classes.carouselControls,
            indicator: classes.carouselIndicator,
          }}
        >
          {slides}
        </Carousel>
      </Card.Section>
      <Group position="apart" mt="lg">
        <Group spacing={5}></Group>
      </Group>
      <Text fz="md" c="bold" mt="sm">
        {textTwo}
      </Text>
    </Card>
  );
}

const imagesalt = [img01, img02, img03, img04, img05, img06];
function CarouselCardalt() {
  const { classes } = useStyles();
  const slides = imagesalt.map((images) => (
    <Carousel.Slide key={images}>
      <Image src={images} height={220} />
    </Carousel.Slide>
  ));
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  return (
    <Card radius="md" withBorder padding="xl">
      <Card.Section>
        <Carousel
          withIndicators
          loop
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          classNames={{
            root: classes.carousel,
            controls: classes.carouselControls,
            indicator: classes.carouselIndicator,
          }}
        >
          {slides}
        </Carousel>
      </Card.Section>
      <Group position="apart" mt="lg">
        <Group spacing={5}>
          <Text fz="xs" fw={500}></Text>
        </Group>
      </Group>
      <Text fz="md" c="bold" mt="sm">
        {textOne}
      </Text>
    </Card>
  );
}
export default function Reflorestation() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { classes } = useStyles();
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
      className="mt-12 flex justify-center items-center h-50"
    >
      <div className="rounded-xl shadow-xl ring-1 ring-gray-400/10">
        <div className="mt-40">
          <Container className="sm:flex items-center justify-center">
            <div className={classes.inner}>
              <div className={classes.content}>
                <Title className={classes.title}>
                  <Text
                    component="span"
                    variant="gradient"
                    gradient={{ from: "#078513", to: "#c7f800" }}
                    inherit
                  >
                    Reflorestamento
                  </Text>
                  <br />
                </Title>
                <CarouselCard />
              </div>
            </div>
            <div className={`ml-4 ${classes.inner}`}>
              <div className={classes.content}>
                <Title className={classes.title}>
                  <Text
                    component="span"
                    variant="gradient"
                    gradient={{ from: "#078513", to: "#c7f800" }}
                    inherit
                  >
                    Area Preservada
                  </Text>
                  <br />
                </Title>
                <CarouselCardalt />
              </div>
            </div>
          </Container>
        </div>
      </div>
    </motion.div>
  );
}
