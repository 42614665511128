import Landing from "../Landing";
import Infos from "../infos";
import Topheader from "../topHeader/";
import About from "../about";
import Clients from "../clients";
import Footer from "../../../footer";
import Video from "../video";
import MapContainer from "../map";
import Products from "../products";
import { Helmet } from "react-helmet";
import FeatureSection from "../features";
import FloatingButton from "../../../floating";
import MyNavbar from "../../../Navbar";
import { ReciclationHomeScreen } from "../Reciclation";
export default function Main() {
  return (
    <div>
      <Helmet title="Agua Mineral Palmares" />
      <FloatingButton />
      <Topheader />
      <MyNavbar />
      <Landing />
      <Clients />
      <FeatureSection />
      <Products />
      <Infos />
      <ReciclationHomeScreen />
      <About />
      <Video />
      <MapContainer />
      <Footer />
    </div>
  );
}
