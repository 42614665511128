import {
  createStyles,
  Image,
  Container,
  Title,
  Text,
  rem,
} from "@mantine/core";
import eurecilco from "../../../../imgs/eureciclo.webp";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";
const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: `calc(${theme.spacing.xl} * 2.5)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      flex: 1,
    },
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
}));

export default function Landing() {
  const { classes } = useStyles();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
      className="flex justify-center items-center h-screen"
    >
      <div className="md:w-8/12 md:h-full mt-12 rounded-xl  shadow-xl ring-1 ring-gray-400/10 sm:w-[55rem]">
        <div className="mt-40">
          <Container>
            <div className={classes.inner}>
              <div className={classes.content}>
                <Title className={classes.title}>
                  Compromisso com o{" "}
                  <Text
                    component="span"
                    variant="gradient"
                    gradient={{ from: "#078513", to: "#c7f800" }}
                    inherit
                  >
                    Meio Ambiente
                  </Text>
                  <br />
                </Title>
                <div className="flex items-center justify-center">
                  <img
                    loading="lazy"
                    alt=""
                    src={eurecilco}
                    className="flex sm:hidden"
                  />
                </div>
                <Text color="bold" mt="md">
                  Além da qualidade, cuidar do meio ambiente também é um dos
                  nossos compromissos. Dentro da Fazenda Santo Antônio
                  realizamos anualmente o plantio de árvores nativas, aumentando
                  gradativamente nossa área de preservação. Novos espaços no
                  entorno de nossa captação vão sendo constantemente arborizados
                  e recuperados.
                  <br />A preservação da fauna local também faz parte do nosso
                  compromisso de exploração sustentável. Através da parceria que
                  temos com a{" "}
                  <Text
                    component="span"
                    variant="gradient"
                    style={{ fontWeight: "bold" }}
                    gradient={{ from: "#078513", to: "#c7f800" }}
                    inherit
                  >
                    EURECICLO
                  </Text>
                  , retiramos por ano grande parte dos descartáveis que
                  produzimos e que foram comercializados. Esse é o compromisso
                  da{" "}
                  <Text
                    component="span"
                    variant="gradient"
                    style={{ fontWeight: "bold" }}
                    gradient={{ from: "blue", to: "green" }}
                    inherit
                  >
                    ÁGUA MINERAL PALMARES
                  </Text>
                  &nbsp;com o destino correto do material descartável produzido.
                </Text>
              </div>
              <Image src={eurecilco} className={classes.image} />
            </div>
          </Container>
        </div>
      </div>
    </motion.div>
  );
}
