import MyNavbar from "../../../Navbar";
import Footer from "../../../footer/index.js";
import Faq from "../faq";
import { Helmet } from "react-helmet";
export default function FAQMain() {
  return (
    <div>
      <Helmet title="FAQ" />
      <MyNavbar marginTop="4" />
      <Faq />
      <Footer />
    </div>
  );
}
