import {
  Button,
  Container,
  Group,
  Text,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import { Link } from "react-router-dom";
import image from "../../../../imgs/eureciclo.webp";
import React, { useState, useCallback, useEffect } from "react";
const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
}));

export function ReciclationHomeScreen() {
  const { classes } = useStyles();

  const [marginTop, setMarginTop] = useState("3rem");

  const handleScroll = useCallback(() => {
    if (window.scrollY > 0 && marginTop === "3rem") {
      setMarginTop("0");
    } else if (window.scrollY === 0 && marginTop === "0") {
      setMarginTop("3rem");
    }
  }, [marginTop]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Container>
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              Reciclagem e Preservação do Meio Ambiente
            </Title>
            <Text color="bold" mt="md">
              A reciclagem é uma prática fundamental para a sustentabilidade do
              nosso planeta. Ao reciclar, evitamos a extração de recursos
              naturais e reduzimos a quantidade de resíduos que acabam em
              aterros sanitários. A cada item reciclado, estamos dando uma
              segunda chance ao material, permitindo que ele seja transformado e
              reutilizado em novos produtos, economizando energia e diminuindo a
              emissão de gases do efeito estufa.
            </Text>
            <img
              loading="lazy"
              alt=""
              src={image}
              className="sm:hidden flex sm:h-96"
            />
            <Group mt={30}>
              <Link to="/meioambiente" onClick={handleClick}>
                <Button
                  variant="default"
                  radius="xl"
                  size="md"
                  className={classes.control}
                >
                  Saiba Mais
                </Button>
              </Link>
            </Group>
          </div>
          <img
            loading="lazy"
            alt=""
            src={image}
            className="hidden sm:flex sm:h-96"
          />
        </div>
      </Container>
    </div>
  );
}
