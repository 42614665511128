import React from "react";
import {
  createStyles,
  Container,
  Title,
  Button,
  Group,
  Text,
  rem,
} from "@mantine/core";
import { IconMap2 } from "@tabler/icons-react";
import "./style.css";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";
const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    position: "relative",
    color: "#272066",
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
}));

function MapContainer() {
  const { classes } = useStyles();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
      className="flex justify-center items-center h-screen"
    >
      <div className="md:w-[55rem] mt-12 rounded-xl shadow-xl ring-1 ring-gray-400/10">
        <div>
          <Container>
            <div className={classes.inner}>
              <div className={classes.content}>
                <Title className={classes.title}>
                  Estamos localizados no município de Palmares Paulista no
                  interior do estado de São Paulo.
                </Title>
                <Text color="dimmed" mt="md">
                  Estrada Vicinal Palmares Paulista - Ariranha - Rural, Palmares
                  Paulista - SP, 15828-000
                </Text>
                <Group mt={30}>
                  <a href="https://www.google.com/maps/dir//Empresa+de+Minera%C3%A7%C3%A3o+Gomieri+Ltda.+-+Estrada+Vicinal+Palmares+Paulista+%C3%A0+Ariranha+Km+01+S%C3%ADtio+-+Santo+Ant%C3%B4nio,+Palmares+Paulista+-+SP,+15828-000,+Brasil/@-21.088345,-48.792785,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x94bc022bfb8fa187:0x81a42d9c2a57f856!2m2!1d-48.7930424!2d-21.0885851?hl=pt-BR">
                    <Button
                      variant="text-primary"
                      radius="xl"
                      size="md"
                      className="bg-blue-500 text-white"
                    >
                      <IconMap2 /> Maps
                    </Button>
                  </a>
                </Group>
              </div>
              <iframe
                className={classes.image}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7445.226731472872!2d-48.797246!3d-21.088099!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94bc022bfb8fa187%3A0x81a42d9c2a57f856!2sEmpresa%20de%20Minera%C3%A7%C3%A3o%20Gomieri%20Ltda.!5e0!3m2!1spt-BR!2sus!4v1680727098661!5m2!1spt-BR!2sus"
                width="600"
                height="450"
                title="Map"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </div>
          </Container>
        </div>
      </div>
    </motion.div>
  );
}

export default MapContainer;
