import {
  createStyles,
  Container,
  Title,
  Text,
  List,
  ThemeIcon,
  rem,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import image from "../../../../imgs/bottleInfo.webp";
import { motion, useInView, useAnimation } from "framer-motion";
import React, { useRef, useEffect } from "react";
const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    position: "relative",
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
}));

export default function About() {
  const { classes } = useStyles();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <div className="mt-64 pb-40 sm:mt-24 sm:pb-0 flex justify-center items-center h-screen">
      <div className="mt-8  sm:w-[55rem] rounded-xl  shadow-xl ring-1 ring-gray-400/10">
        <Container>
          <motion.div
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.25 }}
            className={`mt-96 sm:mt-0 ${classes.inner}`}
          >
            <div className={classes.content}>
              <Title className={classes.title}>
                66 anos de{" "}
                <span className={classes.highlight}>experiência</span>
              </Title>
              <Text color="bold" mt="md">
                A Água Mineral Palmares é sinônimo não somente de saúde mas
                também de muito orgulho, pois está presente nas mesas de
                milhares de famílias, restaurantes, lanchonetes, academias,
                empresas, consultórios médicos e odontológicos.
              </Text>
              <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon size={20} radius="xl">
                    <IconCheck size={rem(12)} stroke={1.5} />
                  </ThemeIcon>
                }
              >
                <img
                  loading="lazy"
                  alt=""
                  src={image}
                  className={`mt-4 flex sm:hidden h-[30rem] rounded-lg`}
                />
                <List.Item className="mt-12">
                  <b>Mineral</b> – Não importa qual é o seu estilo de vida. A
                  Água Mineral Palmares possui minerais indispensáveis para quem
                  leva uma vida agitada e também para que já está na Melhor
                  Idade. Além de ser uma água alcalina - pH 9,11 também possui
                  Vanádio em sua composição.
                </List.Item>
                <List.Item>
                  <b>Saudável</b> – A Água Mineral Palmares atende aos paladares
                  mais exigentes, hidrata e mata a sede de verdade.
                </List.Item>
                <List.Item>
                  <b>Qualidade</b> – Sinônimo de pureza e qualidade, a extração
                  e o envase dos nossos produtos são feitos mecanicamente sem
                  nenhum contato humano, garantindo a qualidade final atestada
                  em laboratório próprio e também em laboratórios terceirizados.{" "}
                </List.Item>
              </List>
            </div>
            <img
              loading="lazy"
              alt=""
              src={image}
              className={`${classes.image} h-[30rem]`}
            />
          </motion.div>
        </Container>
      </div>
    </div>
  );
}
