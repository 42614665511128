import {
  createStyles,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
} from "@mantine/core";
import {
  IconAward,
  IconTargetArrow,
  IconHeartHandshake,
} from "@tabler/icons-react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";

const mockdata = [
  {
    title: "Politica",
    description:
      "Para nós, proteção ambiental, segurança, qualidade e rentabilidade são objetivos de igual importância. Cada pessoa na empresa é capacitada e responsável pelo processo, assegurando que nossos produtos e sistemas atendam todas as exigências.",
    icon: IconAward,
  },
  {
    title: "Missão",
    description:
      "Ser uma empresa de excelência em todos os setores em que atua. Portanto, comprometida com o melhor resultado e produto em todos os aspectos",
    icon: IconTargetArrow,
  },
  {
    title: "Valores",
    description:
      "Foco no cliente, comprometimento, ética e respeito, proatividade, orientação para resultado e sustentabilidade.",
    icon: IconHeartHandshake,
  },
];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(34),
    fontWeight: 900,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
    },
  },
}));

export default function Clients() {
  const { classes, theme } = useStyles();
  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
    >
      <feature.icon size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="bold" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
    >
      <Container className="mt-12" size="lg" py="xl">
        <Title order={2} className={classes.title} ta="center" mt="sm">
          Trabalhar buscando qualidade e satisfação para os nossos clientes,
          colaboradores e parceiros.
        </Title>

        <Text c="bold" className={classes.description} ta="center" mt="md">
          A Empresa de Mineração Gomieri Ltda. fornece de forma constante
          produtos e serviços de alta qualidade que satisfaçam as necessidades
          de seus consumidores e estejam de acordo com os regulamentos técnicos
          e os padrões da empresa.{" "}
        </Text>

        <SimpleGrid
          cols={3}
          spacing="xl"
          mt={50}
          breakpoints={[{ maxWidth: "md", cols: 1 }]}
        >
          {features}
        </SimpleGrid>
      </Container>
    </motion.div>
  );
}
