import React from "react";
import { motion } from "framer-motion";
import rainbowRing from "../../imgs/PH.webp";

export default function FloatingButton() {
  return (
    <div>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 400,
          damping: 100,
          duration: 3,
        }}
        className="position-fixed col  d-flex justify-content-end"
        style={{ zIndex: 10, bottom: 0, right: 0, position: "relative" }}
      >
        <img
          loading="lazy"
          alt=""
          src={rainbowRing}
          className="object-scale-down h-36"
        />
      </motion.div>
    </div>
  );
}
