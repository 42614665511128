import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ContactMain from "../pages/Contact/Main";
import History from "../pages/History/Main";
import Main from "../pages/Home/Main";
import Products from "../pages/Products/Main";
import Reciclagem from "../pages/Reciclation/Main";
import { AnimatePresence } from "framer-motion";
import FAQMain from "../pages/FAQ/Main/index.js";
export default function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" Component={Main} />
        <Route path="/produtos" Component={Products} />
        <Route path="/historia" Component={History} />
        <Route path="/contato" Component={ContactMain} />
        <Route path="/meioambiente" Component={Reciclagem} />
        <Route path="/faq" Component={FAQMain} />
      </Routes>
    </AnimatePresence>
  );
}
