import { Link } from "react-router-dom";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect, useState, useCallback } from "react";
import bottles from "../../../../imgs/BANNER.webp";

export default function Products() {
  const [marginTop, setMarginTop] = useState("3rem");

  const handleScroll = useCallback(() => {
    if (window.scrollY > 0 && marginTop === "3rem") {
      setMarginTop("0");
    } else if (window.scrollY === 0 && marginTop === "0") {
      setMarginTop("3rem");
    }
  }, [marginTop]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
      className="relative overflow-hidden bg-white shadow-xl ring-1 ring-gray-400/10"
    >
      <Link to="/produtos" className="no-underline" onClick={handleClick}>
        <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
          <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
            <div className="sm:max-w-lg">
              <h1 className="font text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Clique aqui e conheça nossos produtos
              </h1>
            </div>
            <div>
              <div className="mt-10">
                <div
                  aria-hidden="true"
                  className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                >
                  <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="mr-4 sm:mr-10">
                        <img
                          loading="lazy"
                          src={bottles}
                          alt=""
                          className="sm:h-6/12 mr-4 sm:mr-10"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  );
}
