import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import logonobg from "../../imgs/logonobg.webp";
const navigation = [
  { name: "Home", to: "/", current: false },
  { name: "Produtos", to: "/produtos", current: false },
  { name: "História", to: "/historia", current: false },
  { name: "Contato", to: "/contato", current: false },
  { name: "Meio Ambiente", to: "/meioambiente", current: false },
  { name: "FAQ", to: "/faq", current: false },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MyNavbar({ marginTop }) {
  return (
    <Disclosure as="nav" className={`bg-white mt-${marginTop}`}>
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/">
                    {" "}
                    <img
                      loading="lazy"
                      className="block h-14 w-auto lg:hidden"
                      src={logonobg}
                      alt=""
                    />
                  </Link>
                  <Link to="/">
                    <img
                      loading="lazy"
                      className="hidden h-20 w-auto lg:block"
                      src={logonobg}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="hidden sm:ml-auto mt-4 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className={`no-underline ${classNames(
                          item.current
                            ? "bg-black text-white"
                            : "text-black hover:text-blue-500 hover:shadow-xl ring-1 ring-gray-400/10",
                          "rounded-md px-3 py-2 text-sm"
                        )}`}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.to}
                  className={`no-underline ${classNames(
                    item.current
                      ? "bg-white-900 text-black"
                      : "text-black hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}`}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
